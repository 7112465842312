import * as React from "react";
import Card from "@mui/material/Card";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { StopCircleRounded } from "@mui/icons-material";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import HeaderCollapse from "./HeaderCollapse";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import {
  Alert,
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { useContext } from "react";
import { CostCenterContext, DailyActivitiesContext } from "../contexts";
import { ClockingsContext } from "../contexts";
import { useEffect } from "react";
import { useCostCenters, useDictionary, useUser } from "../hooks";
import { useRef } from "react";
import DailyActivitiesForm from "./DailyActivitiesForm";
import { openModal } from "../hooks/useModals";
import { red } from "@mui/material/colors";
import { sendNotification } from "../hooks/useNotification";

export default function DailyActivitiesPanel() {
  const {
    state: { dailyActivities = [] },
    startActivities,
    stopActivities,
    activitiesInExecutions,
    addFileToActivities,
    getActivities,
    addCommentToActivities,
  } = useContext(DailyActivitiesContext);

  const { state } = useContext(ClockingsContext);
  const [costcenterid, setCostcenterid] = useState(null);

  useEffect(() => {
    if (state.lastClocking?.clockingdirection == "OUT") {
      setCostcenterid(null);
    } else {
      setCostcenterid(state.lastClocking?.costcenterid || null);
    }
  }, [state.lastClocking, costcenterid]);

  const {
    state: { costCenterForId },
    setCostCenterForId,
  } = useContext(CostCenterContext);
  const dictionary = useDictionary();
  const { user, geolocationEnabled } = useUser();

  const [comment, setComment] = useState("");
  // const [comments, setComments] = useState([]);
  const [isNewComment, setIsNewComment] = useState(false);

  const handleAddComment = () => {
    if (comment.trim()) {
      addCommentToActivities(costcenterid, comment, user.id);
      // setComments([...comments, comment]);
      setComment(""); // Resetta il campo di input
      setIsNewComment(false);
    }
  };

  useEffect(() => {
    setCostCenterForId(costcenterid);
  }, [costcenterid]);
  // console.log(costCenterForId);
  const notesRef = useRef(null);
  const timeRef = useRef();
  const dateRef = useRef();
  const positionRef = useRef();

  const handleFileChange = (event) => {
    const fileInput = event.target; // Riferimento all'input file
    const file = fileInput.files[0];
    if (file) {
      // Controllo la dimensione del file (in bytes)
      if (file.size > 5485760) {
        sendNotification(
          "Errore Carimento File",
          "Il file è troppo grande. Dimensione massima consentita: 5 MB.",
          "error",
          5
        );
        fileInput.value = "";
        return; // Interrompe l'esecuzione se il file è troppo grande
      }
      const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result;
        const base64CleanString = base64.split(",")[1];
        addFileToActivities(costcenterid, {
          base64: base64CleanString,
          name: file.name,
        });
      };
      reader.readAsDataURL(file);
      fileInput.value = "";
    }
  };

  const handleStartActClock = (direction, item) => {
    openModal(
      direction === "OUT"
        ? `Termina Intervento`
        : direction === "PAUSE"
        ? `Blocca Intervento`
        : `Inizia Intervento`,
      <DailyActivitiesForm
        title={item.title}
        costcenter={item.costcenter}
        direction={direction}
        geolocationEnabled={geolocationEnabled}
        dateRef={dateRef}
        timeRef={timeRef}
        notesRef={notesRef}
        positionRef={positionRef}
        // lastClocking={lastClocking}
        dictionary={dictionary}
      />,
      null,
      (closeModal) => (
        <Stack direction="row" spacing={2}>
          <Button
            color="success"
            variant="contained"
            size="small"
            onClick={() =>
              direction === "OUT"
                ? stopActivities(
                    dateRef.current,
                    timeRef.current,
                    closeModal,
                    positionRef,
                    user,
                    item,
                    false,
                    true,
                    notesRef.current
                  )
                : direction === "PAUSE"
                ? pauseActivities(
                    dateRef.current,
                    timeRef.current,
                    closeModal,
                    positionRef,
                    user,
                    item
                  )
                : startActivities(
                    dateRef.current,
                    timeRef.current,
                    closeModal,
                    positionRef,
                    user,
                    item
                  )
            }
          >
            {dictionary["confirm"]}
          </Button>
          <Button
            color="error"
            variant="contained"
            size="small"
            onClick={closeModal}
          >
            {dictionary["cancel"]}
          </Button>
        </Stack>
      )
    );
  };

  return (
    <Stack spacing={2} mt={2}>
      <Card>
        <HeaderCollapse
          title={dictionary["dailyactivities"]["onworking"]}
          Icon={AutoStoriesOutlinedIcon}
        />
        {costcenterid && (
          <Box>
            <CardHeader
              action={
                <Box sx={{ display: "flex", gap: 0 }}>
                  <label htmlFor="file-upload-input">
                    <input
                      id="file-upload-input"
                      type="file"
                      accept="*/*"
                      multiple
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <Tooltip title={dictionary["dailyactivities"]["addfile"]}>
                      <IconButton component="span" aria-label="upload files">
                        <NoteAddOutlinedIcon
                          sx={{ height: 30, width: 30 }}
                          color="primary"
                        />
                      </IconButton>
                    </Tooltip>
                  </label>
                  <Tooltip title={dictionary["dailyactivities"]["addcomments"]}>
                    <IconButton
                      aria-label="add comment"
                      onClick={() => setIsNewComment(true)}
                    >
                      <AddCommentOutlinedIcon
                        sx={{ height: 30, width: 30 }}
                        color="primary"
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              }
              title={costCenterForId} // Renderizza direttamente la descrizione
              titleTypographyProps={{
                style: {
                  fontSize: "16px",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                },
              }}
            />
          </Box>
        )}
        {!costcenterid && (
          <Alert severity="error">Nessun Centro Di Costo Attivo</Alert>
        )}
      </Card>

      {/* Modale per i commenti */}
      <Dialog
        open={isNewComment}
        onClose={() => setIsNewComment(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Aggiungi un commento</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Scrivi un commento..."
            value={comment}
            autoFocus
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault(); // Evita comportamenti predefiniti
                handleAddComment(); // Salva quando si preme Enter
              }
            }}
            onChange={(e) => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsNewComment(false)} color="secondary">
            Annulla
          </Button>
          <Button
            onClick={handleAddComment}
            color="primary"
            disabled={!comment.trim()}
            startIcon={<SendOutlinedIcon />}
          >
            Invia
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
