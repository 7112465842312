import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogContent,
  DialogTitle,
  Tabs,
  Tab,
  List,
  ListItem,
  Button,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import moment from "moment";
import { useCostCenterUser, useForm, useUser } from "../hooks";
import { deleteMillisFromTime, getPosition, normalizeTime } from "../utilities";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { ClockingsContext } from "../contexts";
import { useContext } from "react";
import { useState } from "react";

const iconPerson = new L.Icon({
  iconUrl: require("../assets/avatar_user2.png"),
  iconRetinaUrl: require("../assets/avatar_user2.png"),
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: "leaflet-div-icon",
});

const ClockingForm = ({
  dictionary,
  lastClocking,
  dialogOpen,
  direction,
  dateRef,
  timeRef,
  costCenterRef,
  geolocationEnabled,
  setIsDisabled,
  isOut = false,
  isSwitch = false,
  isCalendar = false,
  calendarDate = null,
  calendarTime = null,
  calendarCostcenterid = null,
}) => {
  const [position, setPosition] = React.useState(null);

  const validations = [];
  const DATE_FORMAT = "YYYY-MM-DD";
  const TIME_FORMAT = "HH:mm";

  const user = useUser();
  const [isDefaultId, setIsDefaultId] = useState(false) 

  const { state, setLastClocking } = useContext(ClockingsContext); // Accesso al contesto
  const costcentersforuser = useCostCenterUser(position);
  let costcenterid = isCalendar ? calendarCostcenterid : state.lastClocking?.costcenterid;
  const clockingdirection = state.lastClocking?.clockingdirection;

  const [selectedCostCenter, setSelectedCostCenter] = useState(
    costcenterid || ""
  );
  const [tabIndex, setTabIndex] = useState(0);

  //PROVA DEI COST CENTER CON DIALOG
  const handleTabChange = (event, newValue) => setTabIndex(newValue);

  const handleCostCenterSelect = (costcenter) => {
    setSelectedCostCenter(costcenter);
    costCenterRef.current = costcenter;
    // if (selectedCostCenter === costcenterid) {
    //   setIsDisabled(true);
    // } else {
    //   setIsDisabled(false);
    // }
    // setIsDisabled(false);
    changeHandler("costcenter", costcenter);
  };

  const filteredCostcenters = (category) => {
    if (!costcentersforuser || typeof costcentersforuser !== "object") {
      console.warn("costcentersforuser is not an object or is undefined");
      return [];
    }
    return costcentersforuser[category] || [];
  };


  const {
    values: clockingForm,
    changeHandler,
    errors,
    touched,
    isValid,
  } = useForm(
    {
      date: lastClocking ? lastClocking.declareddate : new Date(),
      time: lastClocking
        ? moment(deleteMillisFromTime(lastClocking.declaredtime), TIME_FORMAT)
        : new Date(),
    },
    validations
  );
  

  useEffect(() => {
    if (user.user.defaultcostcenterid){
      setIsDefaultId(true);
      costcenterid = user.user.defaultcostcenterid;
    }
  }, []);

  useEffect(() => {
    if (!isCalendar){
    const now = lastClocking ? lastClocking.declareddate : new Date();
    dateRef.current = moment(now).format(DATE_FORMAT);
    timeRef.current = lastClocking
      ? moment(deleteMillisFromTime(lastClocking.declaredtime), TIME_FORMAT)
      : moment(now);
    } else {
      dateRef.current = calendarDate;
      timeRef.current = calendarTime;
    }
    if (geolocationEnabled) {
      geoLocalize();
    }
  }, []);

  useEffect(() => {
    setSelectedCostCenter(costcenterid || ""); // Aggiorna lo stato locale al montaggio
  }, [lastClocking, costcenterid, dialogOpen]);

  useEffect(() => {
    if (direction === "OUT") {
      setIsDisabled(false);
    } else if (!selectedCostCenter) {
      setIsDisabled(true);
    } else if (!isCalendar && (direction === "OUTIN" && selectedCostCenter === costcenterid)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [selectedCostCenter, clockingdirection, dialogOpen]);

  useEffect(() => {}, [dialogOpen]);

  // useEffect(() => {
  //     setLastClocking();
  //   }, []);

  useEffect(() => {
    if (isOut || isCalendar || isDefaultId) {
      handleCostCenterSelect(costcenterid);
    }
  }, [isOut, isCalendar]);

  const geoLocalize = async () => {
    const {
      coords: { latitude, longitude },
    } = await getPosition();
    setPosition({ longitude: longitude, latitude: latitude });
  };

  const [address, setAddress] = useState(null);

  useEffect(() => {
    const fetchAddressFromCoordinates = async (latitude, longitude) => {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
        );
        const data = await response.json();

        if (data && data.display_name) {
          setAddress(data.display_name); // Imposta l'indirizzo formattato
        } else {
          setAddress("Indirizzo non disponibile");
        }
      } catch (error) {
        console.error("Errore nel recupero dell'indirizzo:", error);
        setAddress("Errore nel recupero dell'indirizzo");
      }
    };

    if (position) {
      const { latitude, longitude } = position;
      fetchAddressFromCoordinates(latitude, longitude);
    }
  }, [position]);

  return (
    <Grid container spacing={3} sx={{ pt: (theme) => theme.spacing(1) }}>
      {position && (
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            La tua posizione:
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {address ? address : "Caricamento..."}
          </Typography>
        </Grid>
      )}
      <Grid item xs={6} md={4} lg={4}>
        <DatePicker
          inputFormat="DD/MM/YYYY"
          label={dictionary["clockings"]["form"]["date"]}
          value={isCalendar ? dateRef.current : clockingForm.date || ""}
          onChange={
            (newValue) /* it is moment formatted */ => {
              changeHandler("date", newValue?.format(DATE_FORMAT));
              dateRef.current = newValue?.format(DATE_FORMAT);
            }
          }
          renderInput={(params) => (
            <TextField {...params} fullWidth size="small" />
          )}
        />
      </Grid>
      <Grid item xs={6} md={4} lg={4}>
        <TimePicker
          ampm={false}
          label={dictionary["clockings"]["form"]["time"]}
          value={isCalendar ? timeRef.current : clockingForm.time || ""}
          onChange={
            (newValue) /* it is moment formatted */ => {
              changeHandler("time", newValue);
              timeRef.current = newValue;
            }
          }
          renderInput={(params) => (
            <TextField
              id="orario-timbratura"
              {...params}
              fullWidth
              size="small"
            />
          )}
        />
      </Grid>
      {!isOut && (
        <Grid item xs={12} md={12} lg={12}>
          <FormControl
            error={Boolean(touched.costcenter && errors.costcenter)}
            fullWidth
            size="small"
          >
            {/* <InputLabel id="eventType-select">{"Centro di costo"}</InputLabel> */}
            {/* <Select
            id="eventType-select"
            value={selectedCostCenter} 
            disabled={isOut}
            onChange={handleCostCenterChange}
            label={"Centro di Costo"}
          >
            <MenuItem value="">{"Nessun Centro di costo"}</MenuItem>

            {costcenters.map((costcenter, i) => (
              <MenuItem key={i} value={costcenter.id}>
                {costcenter.description}
              </MenuItem>
            ))}
          </Select> */}

            <Tabs value={tabIndex} onChange={handleTabChange}>
              <Tab label="Recenti" />
              <Tab label="Assegnati" />
              <Tab label="Vicini a Me" />
              <Tab label="Tutti" />
            </Tabs>
            {tabIndex === 0 && (
              <List sx={{ maxHeight: "230px", overflowY: "auto" }}>
                {[...filteredCostcenters("recents"), ...filteredCostcenters("default")].map((recents) => (
                  <ListItem
                    key={recents.idcostcenter}
                    onClick={() => {
                      handleCostCenterSelect(recents.idcostcenter);
                    }}
                    sx={{
                      backgroundColor:
                        selectedCostCenter === recents.idcostcenter
                          ? "rgba(0, 0, 255, 0.1)"
                          : "inherit",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 255, 0.2)",
                      },
                    }}
                  >
                    <ListItemText primary={recents.costcenter} />
                  </ListItem>
                ))}
              </List>
            )}
            {tabIndex === 1 && (
              <List sx={{ maxHeight: "230px", overflowY: "auto" }}>
                {filteredCostcenters("assigned").map((assigned) => (
                  <ListItem
                    key={assigned.idcostcenter}
                    onClick={() =>
                      handleCostCenterSelect(assigned.idcostcenter)
                    }
                    sx={{
                      backgroundColor:
                        selectedCostCenter === assigned.idcostcenter
                          ? "rgba(0, 0, 255, 0.1)"
                          : "inherit",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 255, 0.2)",
                      },
                    }}
                  >
                    <ListItemText primary={assigned.costcenter} />
                  </ListItem>
                ))}
              </List>
            )}
            {tabIndex === 2 && (
              <List sx={{ maxHeight: "230px", overflowY: "auto" }}>
                {filteredCostcenters("near_me").map((nearme) => (
                  <ListItem
                    key={nearme.idcostcenter}
                    onClick={() => handleCostCenterSelect(nearme.idcostcenter)}
                    sx={{
                      backgroundColor:
                        selectedCostCenter === nearme.idcostcenter
                          ? "rgba(0, 0, 255, 0.1)"
                          : "inherit",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 255, 0.2)",
                      },
                    }}
                  >
                    <ListItemText primary={nearme.costcenter} />
                  </ListItem>
                ))}
              </List>
            )}
            {tabIndex === 3 && (
              <List sx={{ maxHeight: "230px", overflowY: "auto" }}>
                {filteredCostcenters("all").map((all) => (
                  <ListItem
                    key={all.idcostcenter}
                    onClick={() => handleCostCenterSelect(all.idcostcenter)}
                    sx={{
                      backgroundColor:
                        selectedCostCenter === all.idcostcenter
                          ? "rgba(0, 0, 255, 0.1)"
                          : "inherit",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 255, 0.2)",
                      },
                    }}
                  >
                    <ListItemText primary={all.costcenter} />
                  </ListItem>
                ))}
              </List>
            )}

            {Boolean(touched.costcenter && errors.costcenter) && (
              <FormHelperText>
                {touched.costcenter && errors.costcenter}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};

export default ClockingForm;
