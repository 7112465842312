import {
  CalendarMonth,
  CalendarViewMonth,
  Label,
  Refresh,
  Search,
  Today,
} from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DailyActivitiesTabels, IconBox } from "../components";
import {
  ClockingsContext,
  CostCenterContext,
  DailyActivitiesContext,
  UsersActivitiesContext,
} from "../contexts";
import {
  useDictionary,
  useForm,
  useUser,
  useCostCenterUser,
  useCostCenters,
} from "../hooks";
import { isDateGreater, isRequired } from "../hooks/useForm";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";

const today = new Date();
const todayDate = today.toISOString().split("T")[0];

const UsersActivities = () => {
  const INITIAL_STATE = {
    datetimefrom: todayDate,
    datetimeto: todayDate,
    // costcenterid: null,
  };

  const { user, setUser } = useUser();

  const costcenters = useCostCenters();
  console.log(costcenters);
  const { state, setLastClocking } = useContext(ClockingsContext);
  const [costcenterid, setCostcenterId] = useState();

  const { addFileToActivities, addCommentToActivities } = useContext(
    UsersActivitiesContext
  );

  // const {
  //   state: { costCenterForId },
  //   setCostCenterForId,
  // } = useContext(CostCenterContext);

  const [filerButton, setFilterButton] = useState({
    today: true,
    week: false,
    month: false,
  });

  const dictionary = useDictionary();
  const validations = [
    ({ datetimefrom, datetimeto }) =>
      isDateGreater(datetimefrom, datetimeto) || {
        datetimefrom: dictionary["reports"]["errors"]["startGreaterEnd"],
      },
    ({ datetimefrom, costcenterid }) =>
      isRequired(datetimefrom) ||
      isRequired(costcenterid !== "ALL" ? costcenterid : null) || {
        datetimefrom: "Data Richiesta",
        costcenterid: "Centro di Costo Richiesto",
      },
  ];

  const {
    state: { dailyActivities },
    getActivities,
  } = useContext(UsersActivitiesContext);

  const {
    values: searchDailyAct,
    changeHandler,
    setValues,
    errors,
    touched,
    isValid,
    reset,
  } = useForm(INITIAL_STATE, validations);

  // const [comment, setComment] = useState("");
  // const [isNewComment, setIsNewComment] = useState(false);

  // const handleAddComment = async () => {
  //   if (comment.trim()) {
  //     try {
  //       // Aspetta che il commento venga aggiunto
  //       await addCommentToActivities(costcenterid, comment, user.id);

  //       // Resetta il campo di input e stato
  //       setComment("");
  //       setIsNewComment(false);
  //     } catch (error) {
  //       console.error("Errore durante l'aggiunta del commento:", error);
  //       sendNotification(
  //         "Errore Aggiunta Commento",
  //         "Si è verificato un errore durante l'aggiunta del commento.",
  //         "error",
  //         5
  //       );
  //     }
  //   }
  // };

  // const fileInputRef = useRef(null);
  // const handleButtonClick = () => {
  //   if (fileInputRef.current) {
  //     fileInputRef.current.click();
  //   }
  // };

  const handleReset = () => {
    reset();
    setFilterButton({
      today: true,
      week: false,
      month: false,
    });
    getActivities(user, todayDate, todayDate, costcenterid);
  };

  const handleFileChange = async (event) => {
    const fileInput = event.target; // Riferimento all'input file
    const file = fileInput.files[0];

    if (file) {
      // Controllo la dimensione del file (in bytes)
      if (file.size > 5485760) {
        sendNotification(
          "Errore Caricamento File",
          "Il file è troppo grande. Dimensione massima consentita: 5 MB.",
          "error",
          5
        );
        fileInput.value = "";
        return; // Interrompe l'esecuzione se il file è troppo grande
      }

      try {
        // Leggi il file come base64 e attendi il risultato
        const base64CleanString = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64 = reader.result;
            resolve(base64.split(",")[1]);
          };
          reader.onerror = (err) => {
            reject(err);
          };
          reader.readAsDataURL(file);
        });

        // Aggiungi il file alle attività
        await addFileToActivities(costcenterid, {
          base64: base64CleanString,
          name: file.name,
        });

        console.log("File aggiunto con successo.");
      } catch (error) {
        console.error("Errore durante il caricamento del file:", error);
        sendNotification(
          "Errore Caricamento File",
          "Si è verificato un errore durante il caricamento del file.",
          "error",
          5
        );
      } finally {
        fileInput.value = ""; // Pulisci l'input file
      }
    }
  };

  useEffect(() => {
    getActivities(user, todayDate, todayDate, costcenterid);
  }, [user]);

  // Get the date seven days from now
  const lastWeek = new Date(today);
  lastWeek.setDate(today.getDate() - 7);
  const lastWeekDate = lastWeek.toISOString().split("T")[0];

  // Get the date one month from now
  const lastMonth = new Date(today);
  lastMonth.setMonth(today.getMonth() - 1);
  const lastMonthDate = lastMonth.toISOString().split("T")[0];

  const ButtonFastFilter = () => {
    return (
      <Grid item container spacing={3}>
        <Grid item xs={12} md={12} lg={4}>
          <Button
            id="today-button"
            fullWidth
            startIcon={<Today />}
            variant={filerButton.today ? "contained" : "outlined"}
            onClick={() => {
              setFilterButton({ today: true, week: false, month: false }),
                setValues({
                  ...searchDailyAct,
                  datetimefrom: todayDate,
                  datetimeto: todayDate,
                });
              getActivities(user, todayDate, todayDate, costcenterid);
            }}
          >
            {dictionary["dailyactivities"]["showToday"]}
          </Button>
        </Grid>

        <Grid item xs={12} md={12} lg={4}>
          <Button
            id="week-button"
            fullWidth
            startIcon={<CalendarViewMonth />}
            variant={filerButton.week ? "contained" : "outlined"}
            onClick={() => {
              setFilterButton({ today: false, week: true, month: false }),
                setValues({
                  ...searchDailyAct,
                  datetimefrom: lastWeekDate,
                  datetimeto: todayDate,
                });
              getActivities(user, lastWeekDate, todayDate, costcenterid);
            }}
          >
            {dictionary["dailyactivities"]["showWeek"]}
          </Button>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Button
            id="month-button"
            fullWidth
            startIcon={<CalendarMonth />}
            variant={filerButton.month ? "contained" : "outlined"}
            onClick={() => {
              setFilterButton({ today: false, week: false, month: true }),
                setValues({
                  ...searchDailyAct,
                  datetimefrom: lastMonthDate,
                  datetimeto: todayDate,
                });
              getActivities(user, lastMonthDate, todayDate, costcenterid);
            }}
          >
            {dictionary["dailyactivities"]["showMonth"]}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Stack spacing={2}>
      <Card>
        <IconBox Icon={Search} />
        <CardHeader
          title={`${dictionary["dailyactivities"]["searchTitleUser"]}`}
          sx={{ pl: (theme) => theme.spacing(12) }}
        />
        <CardContent component="form">
          <Grid container spacing={2} mb={1}>
            <Grid item xs={12}>
              <ButtonFastFilter />
            </Grid>
            <Grid item xs={12}>
            <Grid item container spacing={3}>
            <Grid item xs={12} md={12} lg={4}>
            <FormControl fullWidth size="small" variant="outlined">
                <InputLabel id="costcenter-label">Centro di Costo</InputLabel>
                <Select
                  labelId="costcenter-label"
                  id="costcenter-select"
                  value={costcenterid}
                  label={"Centro di Costo"}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    console.log(selectedValue);
                    setCostcenterId(selectedValue);
                    getActivities(
                      user,
                      searchDailyAct.datetimefrom,
                      searchDailyAct.datetimeto,
                      selectedValue
                    );
                  }}
                >
                  <MenuItem value="">Nessun Centro di Costo</MenuItem>
                  {costcenters.map((costcenter, i) => (
                    <MenuItem key={i} value={costcenter.id}>
                      {costcenter.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
                  <DatePicker
                    label={dictionary["reports"]["dateFrom"]}
                    inputFormat="DD/MM/yyyy"
                    value={searchDailyAct.datetimefrom || ""}
                    onChange={(newValue) =>
                      changeHandler(
                        "datetimefrom",
                        newValue?.format("yyyy-MM-DD")
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        id="field-datafrom"
                        size="small"
                        {...params}
                        sx={{ textTransform: "capitalize" }}
                        error={Boolean(
                          touched.datetimefrom && errors.datetimefrom
                        )}
                        helperText={touched.datetimefrom && errors.datetimefrom}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    id="field-datato"
                    label={dictionary["reports"]["dateTo"]}
                    inputFormat="DD/MM/yyyy"
                    value={searchDailyAct.datetimeto || ""}
                    onChange={(newValue) =>
                      changeHandler(
                        "datetimeto",
                        newValue?.format("yyyy-MM-DD")
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        sx={{ textTransform: "capitalize" }}
                        error={Boolean(touched.datetimeto && errors.datetimeto)}
                        helperText={touched.datetimeto && errors.datetimeto}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Button
                    id="search-activity-btn"
                    variant="contained"
                    color="success"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                    }}
                    startIcon={<Search />}
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      getActivities(
                        user,
                        searchDailyAct.datetimefrom,
                        searchDailyAct.datetimeto,
                        costcenterid
                      );
                      setFilterButton({
                        today: false,
                        week: false,
                        month: false,
                      });
                    }}
                  >
                    {dictionary["search"]}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    id="reset-button"
                    variant="contained"
                    color="warning"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                    }}
                    startIcon={<Refresh />}
                    onClick={handleReset}
                  >
                    {dictionary["reset"]}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <DailyActivitiesTabels searchedDailyAct={dailyActivities} mini isUser={true} />
    </Stack>
  );
};

export default UsersActivities;
