import createDataContext from "./createContext";
import {openModal} from "../hooks/useModals";
import {Button, Stack} from "@mui/material";
import {ClockingsService, UsersService} from "../api";
import {CP, getPosition, normalizeTime, timeZone} from "../utilities";
import {sendNotification} from "../hooks/useNotification";
import {TIME_FORMAT} from "../utilities";
import {checkIfMobile} from "../utilities";
import moment from "moment";

const SET_LAST_CLOCKING = "set_last_clocking";
const INITIAL_STATE = {
  lastClocking: {},
};

const clockingsReducer = (state, action) => {
  switch (action.type) {
    case SET_LAST_CLOCKING: {
      const {lastClocking} = action.payload;
      return {...state, lastClocking};
    }
    default: {
      return state;
    }
  }
};

const setLastClocking = (dispatch) => async () => {
  const response = await ClockingsService.get("last/1");
  const jsonResponse = response.data;
  const lastClocking = jsonResponse.clockings[0];
  dispatch({type: SET_LAST_CLOCKING, payload: {lastClocking}});
};

const confirmClock = (dispatch) => async (clockingdirection="", date, time, finish, geolocationEnabled, costcenterid) => {
  // dispatch({ type: SET_LOADING });
  let clockPoint = checkIfMobile() ? "MOBILE" : CP;
  if ("ontouchstart" in document.documentElement) {
    clockPoint = "MOBILE";
  }
  let data = {
    cp: clockPoint,
    clockingdirection,
    date,
    time: `${time.format(TIME_FORMAT)}:00`,
    clientdatetimeutc: moment(`${date}T${time.format(TIME_FORMAT)}:00`).toISOString(true),
    costcenterid : costcenterid
  };
  if (geolocationEnabled) {
    const {
      coords: {latitude, longitude},
    } = await getPosition();
    data = {
      ...data,
      geolongitude: longitude,
      geolatitude: latitude,
    };
  }
  await ClockingsService.post(`/${clockPoint}`, data);
  // dispatch({ type: SET_CLOCKED, payload: clockingdirection });
  setLastClocking(dispatch)();
  finish();
};
const deleteLastClocking = (dispatch) => async (dictionary, lastClocking) => {
  const action = async () => await UsersService.delete("/current/lastclocking");
  openModal(dictionary["clockings"]["deleteLastClocking"], null, "warning", (closeModal) => (
    <Stack direction="row" spacing={2}>
      <Button
      id="confirm-delete-lastclocking"
        color="success"
        variant="contained"
        size="small"
        onClick={async () => {
          await action();
          setLastClocking(dispatch)();
          closeModal();
        }}>
        {dictionary["confirm"]}
      </Button>
      <Button color="error" variant="contained" size="small" onClick={closeModal}>
        {dictionary["cancel"]}
      </Button>
    </Stack>
  ));
};

export const {Provider, Context} = createDataContext(
  clockingsReducer,
  {
    setLastClocking,
    confirmClock,
    deleteLastClocking,
  }, // actions
  INITIAL_STATE // initial state
);
