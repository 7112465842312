import {
  CalendarMonth,
  CalendarViewMonth,
  Refresh,
  Search,
  Today,
  AddTwoTone,
} from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { IconBox } from "../components";
import { CostCenterContext, DailyActivitiesContext } from "../contexts";
import { useDictionary, useForm, useUser } from "../hooks";
import { isDateGreater, isRequired } from "../hooks/useForm";
import { useNavigate } from "react-router-dom";
import CalendarActivities from "../components/CalendarActivities";
import moment from "moment";

const CalendarForm = ({ title = "Pianificazione" }) => {
  const today = new Date();
  const todayDate = today.toISOString().split("T")[0];
  console.log("todayDate: ", todayDate);

  const { user } = useUser();
  const INITIAL_STATE = {
    datetimefrom: todayDate,
    datetimeto: todayDate,
    costcenterid: null,
    userid: null,
  };

  // const [filerButton, setFilterButton] = useState({
  //   today: true,
  //   week: false,
  //   month: false,
  // });
  const navigate = useNavigate();

  const dictionary = useDictionary();
  const validations = [
    ({ datetimefrom, datetimeto }) =>
      isDateGreater(datetimefrom, datetimeto) || {
        datetimefrom: dictionary["reports"]["errors"]["startGreaterEnd"],
      },
    ({ datetimefrom }) =>
      isRequired(datetimefrom) || {
        datetimefrom: "Data obbligatoria",
      },
  ];

  const {
    state: { dailyActivities, userListForDailyActivities },
    getActivitiesByUser,
  } = useContext(DailyActivitiesContext);

  const {
    state: { costCenterUser },
    setCostCenterUser,
  } = useContext(CostCenterContext);

  const handleUserChange = async (event) => {
    event.preventDefault();
    const userId = event.target.value;
    changeHandler("userid", userId);
    setCostCenterUser(userId, 1);
  };

  const {
    values: searchDailyAct,
    changeHandler,
    setValues,
    errors,
    touched,
    isValid,
    reset,
  } = useForm(INITIAL_STATE, validations);

  // Get the date seven days from now
  const next2Week = new Date(today);
  next2Week.setDate(today.getDate() + 13);
  const next2WeekDate = next2Week.toISOString().split("T")[0];

  // // Get the date one month from now
  // const nextMonth = new Date(today);
  // nextMonth.setMonth(today.getMonth() + 1);
  // const nextMonthDate = nextMonth.toISOString().split("T")[0];

  useEffect(() => {
    getActivitiesByUser(
      user,
      todayDate,
      next2WeekDate,
      INITIAL_STATE.costcenterid
    );
  }, []);

  useEffect(() => {
    if (searchDailyAct.datetimefrom) {
      const dateFromMoment = moment(searchDailyAct.datetimefrom, "YYYY-MM-DD");
      const newDateTo = dateFromMoment.add(13, "days").format("YYYY-MM-DD");
      changeHandler("datetimeto", newDateTo);
      getActivitiesByUser(
        user,
        searchDailyAct.datetimefrom,
        newDateTo, // Usiamo il nuovo valore aggiornato
        searchDailyAct.costcenterid,
        searchDailyAct.userid
      );
    }
  }, [searchDailyAct.datetimefrom]); // Si attiva quando cambia datetimefrom

  const handleReset = () => {
    reset();
    // setFilterButton({
    //   today: true,
    //   week: false,
    //   month: false,
    // });
    getActivitiesByUser(user, todayDate, todayDate);
  };

  const legendItems = [
    {
      color: "#d1ede3",
      label: dictionary["dailyactivities"]["plannedActivity"],
    },
    {
      color: "#e5dbed",
      label: dictionary["dailyactivities"]["notbeforeActivity"],
    },
    {
      color: "#f8dfd3",
      label: dictionary["dailyactivities"]["performwithinActivity"],
    },
  ];
  // const ButtonFastFilter = () => {
  //   return (
  //     <Grid item container spacing={3}>
  //       <Grid item xs={12} md={12} lg={4}>
  //         <Button
  //           id="today-btn-manager"
  //           fullWidth
  //           startIcon={<Today />}
  //           variant={filerButton.today ? "contained" : "outlined"}
  //           onClick={() => {
  //             setFilterButton({ today: true, week: false, month: false }),
  //               setValues({
  //                 ...searchDailyAct,
  //                 datetimefrom: todayDate,
  //                 datetimeto: todayDate,
  //               });
  //             getActivitiesByUser(
  //               user,
  //               todayDate,
  //               todayDate,
  //               searchDailyAct.costcenterid,
  //               searchDailyAct.userid
  //             );
  //           }}
  //         >
  //           {dictionary["dailyactivities"]["showToday"]}
  //         </Button>
  //       </Grid>

  //       <Grid item xs={12} md={12} lg={4}>
  //         <Button
  //           id="week-btn-manager"
  //           fullWidth
  //           startIcon={<CalendarViewMonth />}
  //           variant={filerButton.week ? "contained" : "outlined"}
  //           onClick={() => {
  //             setFilterButton({ today: false, week: true, month: false }),
  //               setValues({
  //                 ...searchDailyAct,
  //                 datetimefrom: todayDate,
  //                 datetimeto: nextWeekDate,
  //               });
  //             getActivitiesByUser(
  //               user,
  //               todayDate,
  //               nextWeekDate,
  //               searchDailyAct.costcenterid,
  //               searchDailyAct.userid
  //             );
  //           }}
  //         >
  //           {dictionary["dailyactivities"]["showNextWeek"]}
  //         </Button>
  //       </Grid>
  //       <Grid item xs={12} md={12} lg={4}>
  //         <Button
  //           id="month-btn-manager"
  //           fullWidth
  //           startIcon={<CalendarMonth />}
  //           variant={filerButton.month ? "contained" : "outlined"}
  //           onClick={() => {
  //             setFilterButton({ today: false, week: false, month: true }),
  //               setValues({
  //                 ...searchDailyAct,
  //                 datetimefrom: todayDate,
  //                 datetimeto: nextMonthDate,
  //               });
  //             getActivitiesByUser(
  //               user,
  //               todayDate,
  //               nextMonthDate,
  //               searchDailyAct.costcenterid,
  //               searchDailyAct.userid
  //             );
  //           }}
  //         >
  //           {dictionary["dailyactivities"]["showNextMonth"]}
  //         </Button>
  //       </Grid>
  //     </Grid>
  //   );
  // };

  return (
    <Stack spacing={2}>
      <Card>
        <IconBox Icon={Search} />
        <CardHeader title={title} sx={{ pl: (theme) => theme.spacing(12) }} />

        <CardContent component="form">
          <Grid container spacing={2} mb={0}>
            {/* <Grid item xs={12} md={12} lg={12}>
              <ButtonFastFilter />
            </Grid> */}
            <Grid item xs={6} md={4} lg={4}>
              <DatePicker
                label={dictionary["reports"]["dateFrom"]}
                inputFormat="DD/MM/yyyy"
                value={searchDailyAct.datetimefrom || ""}
                onChange={(newValue) =>
                  changeHandler("datetimefrom", newValue?.format("yyyy-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField
                    id="datefrom-manager"
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                    error={Boolean(touched.datetimefrom && errors.datetimefrom)}
                    helperText={touched.datetimefrom && errors.datetimefrom}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12} md={6} lg={6}>
              <DatePicker
                label={dictionary["reports"]["dateTo"]}
                inputFormat="DD/MM/yyyy"
                value={searchDailyAct.datetimeto || ""}
                onChange={(newValue) =>
                  changeHandler("datetimeto", newValue?.format("yyyy-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField
                    id="dateto-manager"
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                    error={Boolean(touched.datetimeto && errors.datetimeto)}
                    helperText={touched.datetimeto && errors.datetimeto}
                  />
                )}
              />
            </Grid> */}
            {/* <Grid item xs={6} md={4} lg={4}>
              <TextField
                fullWidth
                select
                id="assignedto-btn"
                label={dictionary["dailyactivities"]["form"]["assignedto"]}
                type="text"
                value={searchDailyAct.userid}
                autoComplete="current-password"
                error={Boolean(touched.userid && errors.userid)}
                helperText={touched.userid && errors.userid}
                size="small"
                onChange={(e) => handleUserChange(e)}
              >
                {userListForDailyActivities.map((res, idx) => (
                  <MenuItem
                    id={`user-${res.id}`}
                    key={idx}
                    value={res.id}
                  >{`${res.firstname} ${res.lastname}`}</MenuItem>
                ))}
              </TextField>
            </Grid> */}
            <Grid item xs={6} md={4} lg={4}>
              <TextField
                fullWidth
                select
                // disabled={costCenterUser.length == 0}
                id="costcenterto-btn"
                label={dictionary["dailyactivities"]["form"]["costcenter"]}
                type="text"
                autoComplete="current-password"
                value={searchDailyAct.costcenterid || "ALL"}
                error={Boolean(touched.costcenterid && errors.costcenterid)}
                helperText={touched.costcenterid && errors.costcenterid}
                size="small"
                onChange={(e) => changeHandler("costcenterid", e.target.value)}
              >
                <MenuItem value={"ALL"}>Tutti</MenuItem>
                {costCenterUser.map((res, idx) => (
                  <MenuItem
                    id={`costcenterto-${res.id}`}
                    key={idx}
                    value={res.id}
                  >
                    {res.description}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} md={4} lg={4}>
              <Stack direction="row" justifyContent={"flex-end"} spacing={2}>
                <Button
                  id="search-filter-btn"
                  variant="contained"
                  color="success"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                  }}
                  startIcon={<Search />}
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    getActivitiesByUser(
                      user,
                      searchDailyAct.datetimefrom,
                      searchDailyAct.datetimeto,
                      searchDailyAct.costcenterid,
                      searchDailyAct.userid
                    );
                    setFilterButton({
                      today: false,
                      week: false,
                      month: false,
                    });
                  }}
                >
                  {dictionary["search"]}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                  }}
                  startIcon={<Refresh />}
                  onClick={handleReset}
                >
                  {dictionary["reset"]}
                </Button>
              </Stack>
              
            </Grid>
            <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                width="100%"
                mt={3}
                ml={2}
              >
                {/* Bottone a sinistra */}
                {/* <Stack direction="row" justifyContent="flex-start">
                  <Button
                    id="create-new-activity-btn"
                    variant="contained"
                    onClick={() =>
                      navigate(`/daily-activities/new?mode=new&role=manage`)
                    }
                    startIcon={<AddTwoTone />}
                    size="small"
                  >
                    {dictionary["dailyactivities"]["newActivity"]}
                  </Button>
                </Stack> */}

                {/* Box della legenda a destra */}
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  {legendItems.map((item, index) => (
                    <Stack
                      key={index}
                      direction="row"
                      spacing={1}
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          backgroundColor: item.color,
                          borderRadius: "4px",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "0.75rem",
                        }}
                        variant="body2"
                      >
                        {item.label}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
          </Grid>
        </CardContent>
      </Card>
      {/* <DailyActivitiesTabels searchedDailyAct={dailyActivities} mini manage /> */}
      <CalendarActivities
        searchedDailyAct={dailyActivities}
        datefrom={searchDailyAct.datetimefrom}
      ></CalendarActivities>
    </Stack>
  );
};

export default CalendarForm;
