import { Tooltip, Typography } from "@mui/material";
import {
  CalendarToday,
  Dashboard as DashboardIcon,
  DocumentScannerTwoTone,
  Person,
  Report,
  RequestPageOutlined,
  Warning,
  Approval,
  Style,
  Flaky,
  ManageAccounts,
  ManageSearch,
  StackedLineChart,
  AssignmentInd,
  ManageHistory,
  Settings,
  MonetizationOnTwoTone,
  WorkHistoryTwoTone,
  CalendarMonth,
  AutoStoriesOutlined,
} from "@mui/icons-material";
import moment from "moment";
import {
  Anomalies,
  Approvers,
  Calendar,
  Dashboard,
  ManageRequests,
  ManageJustifications,
  Profile,
  Reports,
  Requests,
  Statistics,
  ApproverCollaborators,
  UsersList,
  AnomaliesUsersStatus,
  ReportsUsersStatus,
  ManageStatistics,
  ManageAnalyticalReports,
  Landing,
  CostCenters,
  UserCostAnalytics,
  DailyActivities,
  ManageDailyActivities,
  DailyActivity,
  ViewBase,
} from "./pages";
import { matchPath } from "react-router-dom";
import UsersActivities from "./pages/UsersActivities";
import CalendarForm from "./pages/CalendarForm";

export const CP = process.env.REACT_APP_CP;
export const LANGUAGE_PREFERENCE = process.env.REACT_APP_LANGUAGE_PREFERENCE;
export const STORE_USER = process.env.REACT_APP_STORE_USER;
export const STORE_JWT = process.env.REACT_APP_STORE_USER_JWT;
export const DELETED = 3;
export const REACT_APP_VERSION = process.env.REACT_APP_VERSION;

export const APPROVED = "APPROVED";
export const JUSTIFIED = "JUSTIFIED";
export const TIME_FORMAT = "HH:mm";

export const uuidv4 = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
export const getPosition = () => {
  const promise = new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position);
        },
        (err) => {
          reject(err);
        },
        {
          enableHighAccuracy: true,
        }
      );
    }
  });
  return promise;
};
export const renderHeader = (title) => () => {
  return (
    <Tooltip title={title}>
      <Typography
        variant="subtitle2"
        sx={{
          textTransform: "capitalize",
          minWidth: "fit-content",
          width: "fit-content",
        }}
      >
        {title}
      </Typography>
    </Tooltip>
  );
};

export const checkIfMobile = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return true;
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return true;
  }
  return false;
};

// export const checkIfMobile = () => {
//   const ua = navigator.userAgent;
//   if(/(Windows)/i.test(ua)) {
//     return false;
//   }
//   else if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
//     return true;
//   }
//   else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
//     return true;
//   }
//   return true;
// };

export const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

export const normalizeTime = (number) => {
  return String(number).length > 1 ? String(number) : `0${String(number)}`;
};
export const getFirstDayOfMonth = () => {
  var date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
};
export const createFirstDayOfMonth = (date) => {
  date = new Date(date);
  return new Date(date.getFullYear(), date.getMonth(), 1);
};
export const translateMinutesInTime = (minutes) => {
  if (!minutes) {
    return "00:00";
  }
  const hours = Math.floor(Number(minutes) / 60);
  return `${normalizeTime(hours)}:${normalizeTime(
    minutes !== 0 ? minutes % 60 : 0
  )}`;
};
export const translateTimeInMinutes = (time) => {
  const [hours, minutes] = splitTime(time);
  return parseInt(minutes) + parseInt(hours) * 60;
};
export const splitTime = (value) => {
  if (!value) {
    return "";
  }
  return value.split(":");
};
export const deleteMillisFromTime = (value) => {
  const [hours, minutes] = splitTime(value);
  return `${hours}:${minutes}`;
};
export const getTranslateStaticsKeys = (key, obj) => {
  let result = key in obj;
  if (result) {
    return obj[key];
  } else {
    return key;
  }
};
export const addMonthsToDate = (date, months) => {
  const d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
};
export const removeMonthsToDate = (date, months) => {
  const d = date.getDate();
  date.setMonth(date.getMonth() - months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
};
export const dateDiff = (date, checkDate) => {
  return Math.round(
    moment(date, moment.HTML5_FMT.DATE)
      .startOf("day")
      .diff(moment(checkDate, moment.HTML5_FMT.DATE).startOf("day")) / 86400000
  );
};

export const isSameDate = (date1, date2) => {
  return moment(date1).isSame(date2, "day");
};
export const getLastDayOfMonth = () => {
  var date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};
export const createLastDayOfMonth = (date) => {
  date = new Date(date);
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};
export const createFirstDayOfNextMonth = (date) => {
  date = new Date(date);
  return new Date(date.getFullYear(), date.getMonth() + 1, 1);
};
export const dateToServerFormat = (date) => {
  const day = normalizeTime(date.getDate());
  const month = normalizeTime(date.getMonth() + 1);
  return `${date.getFullYear()}-${month}-${day}`;
};

export const getFifteenDaysAgo = () => {
  var date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 15);
};

export const transformDateToDatetime = (datefrom, dateto) => {
  return {
    dateFrom: datefrom && `${datefrom} 00:00:00`,
    dateTo: dateto && `${dateto} 23:59:00`,
  };
};
export const getJustificationEventIdByTypeId = (justificationsType, typeid) => {
  for (const k in justificationsType) {
    if (typeid == justificationsType[k].validforeventidtype)
      return justificationsType[k].id;
  }
};
export const generateUOW = (original, edited, primaryKey = "id") => {
  let deletedItems = [];
  const mappedOriginalForDelete = original.map((o) => {
    const id = o[primaryKey];
    const editedInOriginal = edited.find((e) => (e[primaryKey] || e) === id);
    if (!editedInOriginal) {
      // non esiste più sposto lo stato su delete
      o.objstatus = 3;
    } else {
      // se ancora esisto vuol dire che devo aggiornarlo,
      // potrebbe essere ancora lo stesso ma comunque facciamo elimina aggiungi non ci interessa
      const originalInEdited = original.find(
        (o) => (o[primaryKey] || o) === id
      );
      deletedItems.push({ ...originalInEdited, objstatus: 3 });
      return {
        ...editedInOriginal,
        objstatus: 0,
        [primaryKey]: id,
      };
    }
    return {
      ...o,
      [primaryKey]: id,
    };
  });
  // devo aggiungere i nuovi inseriti
  // quindi quelli che non esistevano nell'originale
  // mi trovo quelli da aggiungere
  const mappedOriginalFormatForAdd = edited
    .filter(
      (x) => !original.find((ox) => ox[primaryKey] === (x[primaryKey] || x))
    )
    .map((x) => ({
      ...x,
      [primaryKey]: x[primaryKey] || x,
      objstatus: 0,
      // objstatus: "osDirty",
      objversion: undefined,
    }));
  return [
    ...deletedItems,
    ...mappedOriginalForDelete,
    ...mappedOriginalFormatForAdd,
  ];
};
export const commissionsValidity = (commissions, dateToJustification) => {
  const DATE_FORMAT = "YYYY-MM-DD";
  let dateFromTo;
  let dateFromValidity;
  let filterCommission = [];
  commissions.map((oneCommission) => {
    let startDate = oneCommission.datestart
      ? moment(oneCommission.datestart).format(DATE_FORMAT)
      : moment(addMonthsToDate(new Date(), -1)).format(DATE_FORMAT);
    let endDate = oneCommission.dateend
      ? moment(oneCommission.dateend).format(DATE_FORMAT)
      : moment(addMonthsToDate(new Date(), 1)).format(DATE_FORMAT);
    let validityDate = oneCommission.dateendvalidity
      ? moment(oneCommission.dateendvalidity).format(DATE_FORMAT)
      : moment(addMonthsToDate(new Date(), 1)).format(DATE_FORMAT);
    dateFromTo = moment(dateToJustification).isBetween(startDate, endDate);
    dateFromValidity = moment(dateToJustification).isBetween(
      startDate,
      validityDate
    );
    if (dateFromTo && dateFromValidity && oneCommission.isenabled) {
      filterCommission = [
        ...filterCommission,
        {
          id: oneCommission.id,
          description: oneCommission.description,
        },
      ];
    }
  });
  return filterCommission;
};
export const getCompiledHours = (justifications) => {
  let result = 0;
  for (const justification of justifications) {
    if (justification.status !== "NOT_APPROVED") {
      result += justification.minutescount;
    }
  }
  return translateMinutesInTime(result);
};
export const ROLES = {
  HOLIDAY_APPROVER: "HOLIDAY_APPROVER",
  PERMIT_APPROVER: "PERMIT_APPROVER",
  JUSTIFICATION_APPROVER: "JUSTIFICATION_APPROVER",
  STATISTICS_BASE: "STATISTICS_BASE",
  SUPERVISOR: "SUPERVISOR",
  EMPLOYEE: "EMPLOYEE",
  DONT_REQUIRE_LOCALIZATION: "DONT_REQUIRE_LOCALIZATION",
  DAILYACTIVITIES_CREATOR: "DAILYACTIVITIES_CREATOR",
  BANK_HOURS: "BANK_HOURS",
};
export const findBreadcrumb = () => {
  let activeRoute = routes.find((route) =>
    Boolean(matchPath(route.path, location.pathname))
  );
  if (!activeRoute) {
    for (const route of routes) {
      if (route.children && route.children.length > 0) {
        activeRoute = route.children.find((child) =>
          Boolean(matchPath(`${route.path}${child.path}`, location.pathname))
        );
        if (activeRoute) break;
      }
    }
  }
  return activeRoute ? activeRoute.breadcrumb : "404";
};
export const userHasOneOfRoles = (matchRoles, { roles }) => {
  const _roles = roles?.split("|");
  if (!_roles) {
    return false;
  }
  if (matchRoles.length > 0) {
    for (const matchRole of matchRoles) {
      if (_roles.indexOf(matchRole) >= 0) {
        return true;
      }
    }
    return false;
  }
  return true;
};
export const routes = [
  {
    id: 0,
    breadcrumb: "dashboard",
    icon: <DashboardIcon />,
    element: Dashboard,
    path: "/",
    inSidebar: true,
    inAppbar: true,
    need: [],
  },
  {
    id: 1,
    breadcrumb: "calendar",
    icon: <CalendarToday />,
    element: Calendar,
    path: "/calendar",
    inSidebar: true,
    inAppbar: true,
    need: [],
  },
  {
    id: 2,
    breadcrumb: "userCalendar",
    icon: <CalendarToday />,
    element: Calendar,
    path: "/calendar/:id",
    inSidebar: false,
    inAppbar: false,
    need: [
      ROLES.JUSTIFICATION_APPROVER,
      ROLES.PERMIT_APPROVER,
      ROLES.HOLIDAY_APPROVER,
      ROLES.SUPERVISOR,
    ],
  },
  {
    id: 3,
    breadcrumb: "diary",
    icon: <AutoStoriesOutlined />,
    path: "",
    inSidebar: true,
    inAppbar: true,
    need: [],
    children: [
      {
        id: 30,
        breadcrumb: "costcenterdiary",
        icon: <AutoStoriesOutlined />,
        path: "/daily-activities",
        element: DailyActivities,
        inSidebar: true,
        inAppbar: true,
        need: [],
      },
      {
        id: 31,
        breadcrumb: "userdiary",
        icon: <AutoStoriesOutlined />,
        path: "/users-activities",
        element: UsersActivities,
        inSidebar: true,
        inAppbar: true,
        need: [],
      },
    ],
  },
  {
    id: 4,
    breadcrumb: "dailyActivities",
    icon: <WorkHistoryTwoTone />,
    element: DailyActivity,
    path: "/daily-activities/:id",
    inSidebar: false,
    inAppbar: false,
    need: [],
  },
  {
    id: 13,
    breadcrumb: "dailyActivities",
    icon: <WorkHistoryTwoTone />,
    element: CalendarForm,
    path: "/calendar/daily-activities",
    inSidebar: false,
    inAppbar: false,
    need: [],
  },
  {
    id: 5,
    breadcrumb: "reports",
    icon: <DocumentScannerTwoTone />,
    element: Reports,
    path: "/reports",
    inSidebar: true,
    inAppbar: true,
    need: [],
  },
  {
    id: 6,
    breadcrumb: "anomalies",
    icon: <Warning />,
    element: Anomalies,
    path: "/anomalies",
    inSidebar: true,
    inAppbar: true,
    need: [],
  },
  {
    id: 7,
    breadcrumb: "requests",
    icon: <RequestPageOutlined />,
    element: Requests,
    path: "/requests",
    inSidebar: true,
    inAppbar: true,
    need: [],
  },
  {
    id: 8,
    breadcrumb: "statistics",
    icon: <Report />,
    element: Statistics,
    path: "/statistics",
    inSidebar: true,
    inAppbar: true,
    need: [],
  },
  {
    id: 9,
    breadcrumb: "profile",
    icon: <Person />,
    element: Profile,
    path: "/profile",
    inSidebar: false,
    inAppbar: false,
    need: [],
  },
  {
    id: 10,
    breadcrumb: "approvers",
    icon: <Approval />,
    element: Approvers,
    path: "/approvers",
    inSidebar: false,
    inAppbar: false,
    need: [],
  },
  {
    id: 11,
    breadcrumb: "costcenters",
    icon: <MonetizationOnTwoTone />,
    element: CostCenters,
    path: "/costcenter",
    inSidebar: true,
    inAppbar: true,
    need: [],
  },
  {
    id: 20,
    breadcrumb: "costcenters",
    icon: <MonetizationOnTwoTone />,
    element: ViewBase,
    path: "/download/:idActivity/:idFile/:mode",
    inSidebar: false,
    inAppbar: false,
    need: [],
  },
  {
    id: 12,
    breadcrumb: "collaborators",
    icon: <Settings />,
    path: "/collaborators",
    children: [
      {
        id: 13,
        breadcrumb: "manageDailyActivities",
        icon: <CalendarMonth />,
        path: "/daily-activities",
        element: ManageDailyActivities,
        inSidebar: true,
        inAppbar: true,
        need: [
          ROLES.JUSTIFICATION_APPROVER,
          ROLES.PERMIT_APPROVER,
          ROLES.HOLIDAY_APPROVER,
          ROLES.SUPERVISOR,
          ROLES.DAILYACTIVITIES_CREATOR,
        ],
      },
      {
        id: 14,
        breadcrumb: "manageDailyActivities",
        icon: <CalendarMonth />,
        path: "/daily-activities/:id",
        element: ManageDailyActivities,
        inSidebar: false,
        inAppbar: false,
        need: [
          ROLES.JUSTIFICATION_APPROVER,
          ROLES.PERMIT_APPROVER,
          ROLES.HOLIDAY_APPROVER,
          ROLES.SUPERVISOR,
          ROLES.DAILYACTIVITIES_CREATOR,
        ],
      },
      {
        id: 15,
        breadcrumb: "manageRequests",
        icon: <Style />,
        path: "/manage-requests",
        element: ManageRequests,
        inSidebar: true,
        inAppbar: true,
        need: [ROLES.HOLIDAY_APPROVER, ROLES.PERMIT_APPROVER],
      },
      {
        id: 16,
        breadcrumb: "manageJustifications",
        icon: <ManageHistory />,
        element: ManageJustifications,
        path: "/manage-justifications",
        inSidebar: true,
        inAppbar: true,
        need: [ROLES.JUSTIFICATION_APPROVER],
      },
      {
        id: 17,
        breadcrumb: "anomaliesToBeJustified",
        icon: <Flaky />,
        element: AnomaliesUsersStatus,
        path: "/anomalies-to-be-justified",
        inSidebar: true,
        inAppbar: true,
        need: [ROLES.JUSTIFICATION_APPROVER],
      },
      {
        id: 18,
        breadcrumb: "reportsToCompile",
        icon: <ManageSearch />,
        element: ReportsUsersStatus,
        path: "/reports-to-compile",
        inSidebar: true,
        inAppbar: true,
        need: [ROLES.JUSTIFICATION_APPROVER],
      },
      {
        id: 19,
        breadcrumb: "manageStatistics",
        icon: <StackedLineChart />,
        path: "/statistics",
        element: ManageStatistics,
        inSidebar: true,
        inAppbar: true,
        need: [ROLES.STATISTICS_BASE],
      },
      {
        id: 20,
        breadcrumb: "attendance",
        icon: <AssignmentInd />,
        path: "/attendance",
        element: UsersList,
        inSidebar: true,
        inAppbar: true,
        need: [

        ],
      },
      {
        id: 21,
        breadcrumb: "rolesPerCollaborator",
        icon: <ManageAccounts />,
        element: ApproverCollaborators,
        path: "/roles-per-collaborator",
        inSidebar: true,
        inAppbar: true,
        need: [
          ROLES.JUSTIFICATION_APPROVER,
          ROLES.PERMIT_APPROVER,
          ROLES.HOLIDAY_APPROVER,
          ROLES.SUPERVISOR,
        ],
      },
      {
        id: 22,
        breadcrumb: "manageStatistics",
        //icon: <StackedLineChart />,
        path: "/statistics/analytical-report/:id",
        element: ManageAnalyticalReports,
        inSidebar: false,
        inAppbar: false,
        need: [ROLES.STATISTICS_BASE],
      },
    ],
    inSidebar: true,
    inAppbar: true,
    need: [
      ROLES.HOLIDAY_APPROVER,
      ROLES.PERMIT_APPROVER,
      ROLES.JUSTIFICATION_APPROVER,
      ROLES.SUPERVISOR,
      ROLES.STATISTICS_BASE,
      ROLES.DAILYACTIVITIES_CREATOR,
    ],
  },
  {
    id: 23,
    breadcrumb: "",
    icon: <DashboardIcon />,
    element: Landing,
    path: "/landing",
    inSidebar: false,
    inAppbar: false,
    need: [],
  },
  {
    id: 24,
    breadcrumb: "",
    icon: <DashboardIcon />,
    element: UserCostAnalytics,
    path: "/costcenter/:id/:rights",
    inSidebar: false,
    inAppbar: false,
    need: [],
  },
];

export const getRedirectUrl = (routes) => {
  switch (routes) {
    case "reports":
      return "/reports";
    case "anomalies":
      return "/anomalies";
    case "costcenters":
      return "/costcenters";
    case "collaborators-justifications":
      return "/collaborators/manage-justifications";
    case "collaborators-requests":
      return "/collaborators/manage-requests";
    case "clocking ":
      return "/";
    default:
      return "/";
  }
};

export const convertBase64String = (str) => {
  // Rimuove eventuali spazi bianchi dalla stringa
  str = str.replace(/\s/g, "");

  // Rimuove il prefisso errato 'dataapplication/pdfbase'
  if (str.startsWith("dataapplication")) {
    str = str.replace("dataapplication", "");
  }

  // Determina il tipo di file
  let fileType = "";
  if (str.startsWith("JVBER")) {
    fileType = "application/pdf";
  } else {
    fileType = "image/png";
  }

  // Restituisce la stringa base64 corretta con il prefisso
  return `data:${fileType};base64,${str}`;
};

export const formattedTimeStats = (time) => {
  let hours = time.toFixed(2).replace(".", ",");
  let parts = hours.split(",");
  let minutesDecimal = parts[1];
  let minutesNotFormatted = Math.round((parseInt(minutesDecimal) * 60) / 100);
  let oraNotFormatted = parts[0];
  let minutes =
    minutesNotFormatted !== 0 ? minutesNotFormatted.toString() : "00";
  let minutinuovi = minutes.length === 1 ? "0" + minutes : minutes;
  let ora =
    oraNotFormatted.length >= 2 ? oraNotFormatted : "0" + oraNotFormatted;
  let formattedTime = ora + ":" + minutinuovi;
  return formattedTime;
};

export const generateUuid = () => {
  // Public Domain/MIT
  let d = new Date().getTime(); // Timestamp
  let d2 = (performance && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = Math.random() * 16; // random number between 0 and 16
    if (d > 0) {
      // Use timestamp until depleted
      // eslint-disable-next-line no-bitwise
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // Use microseconds since page-load if supported
      // eslint-disable-next-line no-bitwise
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    // eslint-disable-next-line no-bitwise
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};
