import { MoreVert, OpenInNew } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ClockingsContext } from "../contexts";
import { useDictionary, useUser } from "../hooks";
import { deleteMillisFromTime } from "../utilities";
import ClockingForm from "./ClockingForm";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";

const UserImage = () => {
  const { user, image, getUserImage } = useUser();
  const [currentuserid, setCurrentuserid] = useState(user.id);

  useEffect(() => {
    if (user.id !== currentuserid) {
      getUserImage("current");
      setCurrentuserid(user.id);
    }
  }, [user]);

  return (
    <Avatar
      sx={{ height: "300px", width: "100%", borderRadius: "unset" }}
      src={image}
      alt={user.emailAddress}
    />
  );
};

const ClockingPanel = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogDirection, setDialogDirection] = useState(null);

  const openMenu = Boolean(anchorEl);
  const timeRef = useRef();
  const dateRef = useRef();
  const costCenterRef = useRef();
  const [isDisabled, setIsDisabled] = useState(true);
  const {
    state: { lastClocking },
    setLastClocking,
    confirmClock,
    deleteLastClocking,
  } = useContext(ClockingsContext);

  const { user, image, getUserImage, geolocationEnabled } = useUser();
  const dictionary = useDictionary();
  let costcenterid = lastClocking?.costcenterid;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleDeleteClocking = () => {
    deleteLastClocking(dictionary, lastClocking);
    handleMenuClose();
  };

  const handleDialogOpen = (direction) => {
    setDialogDirection(direction);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    // setDialogDirection(null);
  };

  useEffect(() => {
    if (user.id && !image) {
      getUserImage("current");
    }
  }, [user, image]);

  useEffect(() => {
    setLastClocking();
  }, []);

  useEffect(() => {
    costCenterRef.current = costcenterid
  }, [costcenterid]);

  useEffect(() => {
  }, [isDisabled]);

  // useEffect(() => {
  //   console.log("CIAO")
  //   setIsDisabled(!costCenterRef.current);
  // }, [costCenterRef.current, dialogOpen]);

  // useEffect(() => {
  //   console.log(lastClocking.clockingdirection)
  //   console.log(costCenterRef.current, lastClocking.costcenterid)
  //   if (dialogDirection !== "OUT" && costCenterRef.current == lastClocking.costcenterid){
  //     setIsDisabled(true);}
  //   // } else if (dialogDirection === "OUT") {
  //   //   setIsDisabled(false);
  //   // }
  // }, [costCenterRef.current, lastClocking.clockingdirection, dialogOpen]);

  return (
    <Card>
      <Box sx={{ position: "relative" }}>
        <IconButton
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 2,
            backgroundColor: "rgba(0,0,0,0.3)",
            color: (theme) => theme.palette.common.white,
          }}
          component={Link}
          to="/profile"
        >
          <OpenInNew />
        </IconButton>
        <UserImage />
      </Box>
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Typography gutterBottom variant="h4" component="div">
              {user.firstname} {user.lastname}
            </Typography>
          </Grid>
          {lastClocking && lastClocking.clockingdirection && (
            <>
              <Grid item xs={12} md={12} lg={12}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold" }}
                  color="text.secondary"
                >
                  {dictionary["clockings"]["lastClocking"]}
                </Typography>
              </Grid>
              <Grid
                sx={{ display: "flex", alignItems: "center" }}
                item
                xs={11}
                md={11}
                lg={11}
              >
                <Typography variant="body1" color="text.secondary">
                  <span style={{ fontWeight: "bold" }}>
                    {
                      dictionary["clockings"]["clockingDirections"][
                        lastClocking.clockingdirection
                      ]
                    }
                  </span>
                  {" >> "}
                  {moment(lastClocking.declareddate).format("ddd:")}&nbsp;
                  {moment(lastClocking.declareddate).format("DD/MM/YYYY")}{" "}
                  -&nbsp;
                  {deleteMillisFromTime(lastClocking.declaredtime)}
                </Typography>
              </Grid>
              <Grid
                sx={{ display: "flex", alignItems: "center" }}
                item
                xs={1}
                md={1}
                lg={1}
              >
                <IconButton
                  size="small"
                  aria-label="more"
                  id="long-button"
                  aria-controls={openMenu ? "long-menu" : undefined}
                  aria-expanded={openMenu ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleMenuOpen}
                >
                  <MoreVert fontSize="small" />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleMenuClose}
                >
                  <MenuItem id="delete-last-btn" onClick={handleDeleteClocking}>
                    {dictionary["clockings"]["deleteLast"]}
                  </MenuItem>
                </Menu>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold" }}
                  color="text.secondary"
                >
                  {dictionary["clockings"]["clockingPoint"]}:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {lastClocking?.clockingpointdescription}
                  </span>
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
      <CardActions>
        {lastClocking?.clockingdirection && (lastClocking?.clockingdirection === "IN" ||
        lastClocking?.clockingdirection === "OUTIN") ? (
          <Button
            startIcon={<ChangeCircleOutlinedIcon />}
            id="clock-outin-btn"
            fullWidth
            variant="contained"
            onClick={() => handleDialogOpen("OUTIN")}
          >
            {dictionary["clockings"]["clockOutIn"]}
          </Button>
        ) : (
          <Button
            startIcon={<LoginOutlinedIcon />}
            id="clock-in-btn"
            fullWidth
            variant="contained"
            disabled={lastClocking?.clockingdirection === "IN"}
            onClick={() => handleDialogOpen("IN")}
          >
            {dictionary["clockings"]["clockIn"]}
          </Button>
        )}
        <Button
          startIcon={<LogoutOutlinedIcon />}
          id="clock-out-btn"
          fullWidth
          disabled={
            lastClocking ? lastClocking.clockingdirection === "OUT" : true
          }
          variant="contained"
          onClick={() => handleDialogOpen("OUT")}
        >
          {dictionary["clockings"]["clockOut"]}
        </Button>
      </CardActions>

      {/* Dialog Component */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            height:
              dialogDirection === "OUT"
                ? "180px"
                : geolocationEnabled
                ? "560px"
                : "490px",
          },
        }}
      >
        <DialogTitle>
          {
            dictionary["clockings"][
              dialogDirection === "OUT"
                ? "clockOut"
                : dialogDirection === "OUTIN"
                ? "clockOutIn"
                : "clockIn"
            ]
          }
        </DialogTitle>
        <DialogContent>
          <ClockingForm
            dialogOpen={dialogOpen}
            direction={dialogDirection}
            geolocationEnabled={geolocationEnabled}
            setIsDisabled={setIsDisabled}
            dictionary={dictionary}
            timeRef={timeRef}
            dateRef={dateRef}
            costCenterRef={costCenterRef}
            {...(dialogDirection === "OUT" && { isOut: true })}
            {...(dialogDirection === "OUTIN" && { isSwitch: true })}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            variant="contained"
            size="small"
            disabled={isDisabled}
            onClick={() => {
              confirmClock(
                dialogDirection,
                dateRef.current,
                timeRef.current,
                handleDialogClose,
                geolocationEnabled,
                costCenterRef.current
              );
            }}
          >
            {dictionary["confirm"]}
          </Button>
          <Button
            color="error"
            variant="contained"
            size="small"
            onClick={handleDialogClose}
          >
            {dictionary["cancel"]}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ClockingPanel;
