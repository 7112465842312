import React, { useContext, useEffect } from 'react'
import { UserContext } from '../contexts';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from "@mui/material";
import { IconBox, UsersTable } from "../components";
import { Refresh, Search } from "@mui/icons-material";
import { useDictionary, useForm, useUser } from "../hooks";
import { ROLES, userHasOneOfRoles } from '../utilities';

const UsersList = () => {
    const INITIAL_STATE = {
        username: ""
    };

    const dictionary = useDictionary();
    const {user} = useUser();
    console.log('user: ', user);
    

    const {
        state: { usersStatus, searchedUsersStatus },
        setUsersStatus,
    } = useContext(UserContext);

    const validations = [];

    const {
        values: searchUsers,
        changeHandler,
        setValues,
        errors,
        touched,
        isValid,
        reset
    } = useForm(INITIAL_STATE, validations);

    useEffect(() => {
        setUsersStatus(searchUsers);
    }, []);

    return (
        <Stack spacing={2}>
            <Card>
                <IconBox Icon={Search} />
                <CardHeader
                    title={dictionary["dashboard"]["userList"]}
                    sx={{ pl: (theme) => theme.spacing(12) }}
                />
                <CardContent component="form">
                    <Grid container spacing={2} mb={1}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField id="outlined-basic"
                                label={dictionary["user"]}
                                variant="outlined"
                                value={searchUsers.username || ""}
                                onChange={(e) => changeHandler("username", e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <Stack direction="row" justifyContent={"flex-end"} spacing={2}>
                                <Button
                                    variant="contained"
                                    color="success"
                                    size="small"
                                    sx={{
                                        textTransform: "capitalize",
                                    }}
                                    disabled={!isValid}
                                    startIcon={<Search />}
                                    type="submit"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setUsersStatus(searchUsers);
                                    }}
                                >
                                    {dictionary["search"]}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    size="small"
                                    sx={{
                                        textTransform: "capitalize",
                                    }}
                                    startIcon={<Refresh />}
                                    onClick={reset}
                                >
                                    {dictionary["reset"]}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <UsersTable searchForm={searchUsers} searchedUsers={searchedUsersStatus} isAdmin={user.isadmin} isSupervisor={userHasOneOfRoles([ROLES.SUPERVISOR], user)} />
                </CardContent>
            </Card>
        </Stack>
    )
}

export default UsersList