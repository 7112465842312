import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Typography,
  Stack,
  Grid,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import moment from "moment";
import { useDictionary, useUser } from "../hooks";
import { Edit } from "@mui/icons-material";
import { openModal } from "../hooks/useModals";
import ClockingForm from "./ClockingForm";
import { CalendarContext, ClockingsContext } from "../contexts";
import { deleteMillisFromTime } from "../utilities";

const ClockingsDetailModal = ({
  clockings,
  closeModalRef,
  firstDay,
  lastDay,
  userid,
}) => {
  console.log(clockings);
  const dictionary = useDictionary();
  const timeRef = useRef();
  const dateRef = useRef();
  const costCenterRef = useRef();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogDirection, setDialogDirection] = useState(null);
  const [calendarDate, setCalendarDate] = useState(null);
  const [calendarTime, setCalendarTime] = useState(null);
  const [calendarCostcenterid, setCalendarCostcenterid] = useState(null);
  const { user, image, getUserImage, geolocationEnabled } = useUser();
  const [isDisabled, setIsDisabled] = useState(true);
  const [clockingid, setClockingid] = useState(true);

  const handleDialogOpen = (direction, id, date, time, costcenterid) => {
    setDialogDirection(direction);
    setClockingid(id);
    setCalendarDate(date);
    const formattedTime = moment(time, "HH:mm:ss");
    setCalendarTime(formattedTime);
    setCalendarCostcenterid(costcenterid);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    // setDialogDirection(null);
  };

  const {
    setCalendarUserEvents,
    setCalendarUserClockings,
    setCalendarUserAnomalies,
    setCalendarUserPermits,
    setCalendarUserHolidays,
    updateClock,
  } = useContext(CalendarContext);

  const {
    state: { lastClocking },
    setLastClocking,
    confirmClock,
    deleteLastClocking,
  } = useContext(ClockingsContext);

  // const handleClock = (direction, item) => {
  //   openModal(
  //     `${dictionary["clockings"]["edit"]} ${dictionary["clockings"]["clockingDirections"][direction]}`,
  //     <ClockingForm
  //       direction={direction}
  //       lastClocking={item}
  //       dictionary={dictionary}
  //       timeRef={timeRef}
  //       dateRef={dateRef}
  //       edit={true}
  //     />,
  //     null,
  //     (closeModal) => (
  //       <Stack direction="row" spacing={2}>
  //         <Button
  //           color="success"
  //           variant="contained"
  //           size="small"
  //           onClick={() =>
  //             updateClock(
  //               item.id,
  //               dateRef.current,
  //               timeRef.current,
  //               () => {
  //                 setCalendarUserEvents(userid, firstDay, lastDay);
  //                 setCalendarUserClockings(firstDay, lastDay);
  //                 setCalendarUserAnomalies(userid, firstDay, lastDay);
  //                 setCalendarUserPermits(userid, firstDay, lastDay);
  //                 setCalendarUserHolidays(userid, firstDay, lastDay);
  //                 closeModal();
  //                 closeModalRef.current();
  //               },
  //               dictionary
  //             )
  //           }
  //         >
  //           {dictionary["confirm"]}
  //         </Button>
  //         <Button
  //           color="error"
  //           variant="contained"
  //           size="small"
  //           onClick={closeModal}
  //         >
  //           {dictionary["cancel"]}
  //         </Button>
  //       </Stack>
  //     )
  //   );
  // };

  return (
    <Stack>
      <Stack flexDirection={"column"} spacing={2}>
        {clockings.map((item, index) => (
          <Grid item xs={12} md={12} lg={12} key={`${index}-clocking-modal`}>
            <Grid container>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="body1">
                  {item.clockingdirection === "IN" ? (
                    <span>
                      <span style={{ fontWeight: "bold" }}>
                        {dictionary["reports"]["IN"]}
                      </span>
                      {" >> "}
                      {moment(item.declareddate).format("ddd:")}&nbsp;
                      {moment(item.declareddate).format("DD/MM/YYYY")} -&nbsp;
                      {deleteMillisFromTime(item.declaredtime)}
                    </span>
                  ) : item.clockingdirection === "OUTIN" ? (
                    <span>
                      <span style={{ fontWeight: "bold" }}>
                        {dictionary["reports"]["OUTIN"]}
                      </span>
                      {" >> "}
                      {moment(item.declareddate).format("ddd:")}&nbsp;
                      {moment(item.declareddate).format("DD/MM/YYYY")} -&nbsp;
                      {deleteMillisFromTime(item.declaredtime)}
                    </span>
                  ) : item.clockingdirection === "OUT" ? (
                    <span>
                      <span style={{ fontWeight: "bold" }}>
                        {dictionary["reports"]["OUT"]}
                      </span>
                      {" >> "}
                      {moment(item.declareddate).format("ddd:")}&nbsp;
                      {moment(item.declareddate).format("DD/MM/YYYY")} -&nbsp;
                      {deleteMillisFromTime(item.declaredtime)}
                    </span>
                  ) : null}
                </Typography>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() =>
                    handleDialogOpen(
                      item.clockingdirection,
                      item.id,
                      item.declareddate,
                      item.declaredtime,
                      item.costcenterid
                    )
                  }
                >
                  <Edit fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">{item.costcenter}</Typography>
            </Grid>
          </Grid>
        ))}
      </Stack>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            height:
              dialogDirection === "OUT"
                ? "180px"
                : geolocationEnabled
                ? "560px"
                : "490px",
          },
        }}
      >
        <DialogTitle>
          {
            dictionary["clockings"][
              dialogDirection === "OUT"
                ? "clockOut"
                : dialogDirection === "OUTIN"
                ? "clockOutIn"
                : "clockIn"
            ]
          }
        </DialogTitle>
        <DialogContent>
          <ClockingForm
            dialogOpen={dialogOpen}
            direction={dialogDirection}
            geolocationEnabled={geolocationEnabled}
            setIsDisabled={setIsDisabled}
            dictionary={dictionary}
            timeRef={timeRef}
            dateRef={dateRef}
            costCenterRef={costCenterRef}
            {...(dialogDirection === "OUT" && { isOut: true })}
            {...(dialogDirection === "OUTIN" && { isSwitch: true })}
            isCalendar={true}
            calendarDate={calendarDate}
            calendarTime={calendarTime}
            calendarCostcenterid={calendarCostcenterid}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            variant="contained"
            size="small"
            disabled={isDisabled}
            onClick={() =>
              updateClock(
                clockingid,
                dateRef.current,
                timeRef.current,
                costCenterRef.current,
                () => {
                  setCalendarUserEvents(userid, firstDay, lastDay);
                  setCalendarUserClockings(firstDay, lastDay);
                  setCalendarUserAnomalies(userid, firstDay, lastDay);
                  setCalendarUserPermits(userid, firstDay, lastDay);
                  setCalendarUserHolidays(userid, firstDay, lastDay);
                  handleDialogClose();
                  closeModalRef.current();
                },
                dictionary
              )
            }
          >
            {dictionary["confirm"]}
          </Button>
          <Button
            color="error"
            variant="contained"
            size="small"
            onClick={handleDialogClose}
          >
            {dictionary["cancel"]}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default ClockingsDetailModal;
