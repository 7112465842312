import {
  CalendarTodayOutlined,
  ErrorOutlineOutlined,
  NoteAltOutlined,
  Person,
} from "@mui/icons-material";
import {
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
  Box,
  Button,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  ClockingPanel,
  CollapsableWindow,
  AnomaliesTables,
  ReportsTables,
  HeaderCollapse,
  HolidaysPanel,
  PermitsPanel,
  ManageRequestsTables,
  ManageJustificationsTables,
  PlannedAbsences,
  BusinesstripsPanel,
  DailyActivitiesPanel,
  BankHoursPanel,
} from "../components";
import {
  AnomaliesContext,
  ManageJustificationsContext,
  ManageRequestsContext,
  ReportsContext,
} from "../contexts";
import { useDictionary, useMediaQuery, useUser } from "../hooks";
import { ROLES, userHasOneOfRoles } from "../utilities";
import AbsencePanel from "../components/AbsencePanel";

const Dashboard = () => {
  const dictionary = useDictionary();
  const [isMobile] = useMediaQuery("down", "md");
  const navigate = useNavigate();
  const {
    state: { searchedReport },
    setReports,
  } = useContext(ReportsContext);
  const {
    state: { anomalies },
    setDashboardAnomalies,
  } = useContext(AnomaliesContext);

  const {
    state: { searchedRequests, plannedAbsences },
    setManageRequests,
    setPlannedAbsences,
  } = useContext(ManageRequestsContext);

  const {
    state: { searchedJustifications },
    setManageJustifications,
  } = useContext(ManageJustificationsContext);

  const { user } = useUser();

  const INITIAL_STATE_Justification = {
    firstname: "ALL",
    lastname: "ALL",
    status: "JUSTIFIED",
    statusGroups: "PENDING",
    datefrom: "ALL",
    dateto: "ALL",
  };

  const INITIAL_STATE_Requests = {
    firstname: "ALL",
    lastname: "ALL",
    status: "NEW",
    statusGroups: "PENDING",
    datetimefrom: "ALL",
    datetimeto: "ALL",
  };

  useEffect(() => {
    setReports({ status: "NOTJUSTIFIED" });
    setDashboardAnomalies(user.id);
    if (
      userHasOneOfRoles([ROLES.HOLIDAY_APPROVER, ROLES.PERMIT_APPROVER], user)
    ) {
      setManageRequests(INITIAL_STATE_Requests);
      setPlannedAbsences();
    }
    if (userHasOneOfRoles([ROLES.JUSTIFICATION_APPROVER], user)) {
      setManageJustifications(INITIAL_STATE_Justification);
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{ display: { xs: "none", md: "flex", lg: "flex" } }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={4}></Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="h6">
              {dictionary["dashboard"]["reportsPanel"]}
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="h6">
              {dictionary["dashboard"]["requestsPanel"]}
            </Typography>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={4}>
            <Stack spacing={2}>
              <ClockingPanel />
              <Button
                startIcon={<Person />}
                sx={{
                  backgroundColor: "#388e3c",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#2e7d32",
                  },
                }}
                variant="contained"
                onClick={() => navigate("collaborators/attendance")}
              >
                {dictionary["dashboard"]["userList"]}
              </Button>
              <Button
                startIcon={<CalendarTodayOutlined />}
                sx={{
                  backgroundColor:"#e64a19",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#d84315",
                  },
                }}
                variant="contained"
                onClick={() => navigate("/calendar/daily-activities")}
              >
                {dictionary["dashboard"]["planning"]}
              </Button>
              <DailyActivitiesPanel />
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            sx={{ display: { xs: "block", md: "block", lg: "block" } }}
          >
            <Stack spacing={2}>
              {isMobile ? (
                <Card>
                  <Link to="/reports">
                    <HeaderCollapse
                      notificationCount={searchedReport.length}
                      color={"reports"}
                      title={dictionary["dashboard"]["reports"]}
                      Icon={NoteAltOutlined}
                    />
                  </Link>
                </Card>
              ) : (
                <CollapsableWindow>
                  <Link to="/reports">
                    <HeaderCollapse
                      notificationCount={searchedReport.length}
                      color={"reports"}
                      title={dictionary["dashboard"]["reports"]}
                      Icon={NoteAltOutlined}
                    />
                  </Link>
                  <Box sx={{ px: (theme) => theme.spacing(1) }}>
                    <ReportsTables searchedReport={searchedReport} mini />
                  </Box>
                </CollapsableWindow>
              )}

              {isMobile ? (
                <Card>
                  <Link to="/anomalies">
                    <HeaderCollapse
                      notificationCount={anomalies.length}
                      color={"anomalies"}
                      title={dictionary["dashboard"]["anomalies"]}
                      Icon={ErrorOutlineOutlined}
                    />
                  </Link>
                </Card>
              ) : (
                <CollapsableWindow>
                  <Link to="/anomalies">
                    <HeaderCollapse
                      notificationCount={anomalies.length}
                      color={"anomalies"}
                      title={dictionary["dashboard"]["anomalies"]}
                      Icon={ErrorOutlineOutlined}
                    />
                  </Link>
                  <Box sx={{ px: (theme) => theme.spacing(1) }}>
                    <AnomaliesTables searchedAnomalies={anomalies} mini />
                  </Box>
                </CollapsableWindow>
              )}
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            sx={{ display: { xs: "block", md: "block", lg: "block" } }}
          >
            <Stack spacing={2}>
              <HolidaysPanel />
              <PermitsPanel />
              <AbsencePanel />
              <BusinesstripsPanel />
              <BankHoursPanel />
              {userHasOneOfRoles(
                [
                  ROLES.HOLIDAY_APPROVER,
                  ROLES.PERMIT_APPROVER,
                  ROLES.JUSTIFICATION_APPROVER,
                ],
                user
              ) && (
                <React.Fragment>
                  <Typography variant="h6">
                    {dictionary["routes"]["collaborators"]}
                  </Typography>
                  <Divider />
                  {isMobile &&
                  userHasOneOfRoles([ROLES.JUSTIFICATION_APPROVER], user) ? (
                    <>
                      <Link to="/collaborators/manage-justifications">
                        <HeaderCollapse
                          notificationCount={searchedJustifications.length}
                          color={"collaborators"}
                          title={dictionary["routes"]["manageJustifications"]}
                          Icon={NoteAltOutlined}
                        />
                      </Link>
                    </>
                  ) : (
                    <CollapsableWindow>
                      <Link to="/collaborators/manage-justifications">
                        <HeaderCollapse
                          notificationCount={searchedJustifications.length}
                          color={"collaborators"}
                          title={dictionary["routes"]["manageJustifications"]}
                          Icon={NoteAltOutlined}
                        />
                      </Link>
                      <Box sx={{ px: (theme) => theme.spacing(1) }}>
                        <ManageJustificationsTables
                          searchedJustifications={searchedJustifications}
                          mini
                        />
                      </Box>
                    </CollapsableWindow>
                  )}
                  {isMobile &&
                  userHasOneOfRoles([ROLES.JUSTIFICATION_APPROVER], user) ? (
                    <>
                      <Link to="/collaborators/manage-requests">
                        <HeaderCollapse
                          notificationCount={searchedRequests.length}
                          color={"collaborators"}
                          title={dictionary["routes"]["manageRequests"]}
                          Icon={NoteAltOutlined}
                        />
                      </Link>
                    </>
                  ) : (
                    <CollapsableWindow>
                      <Link to="/collaborators/manage-requests">
                        <HeaderCollapse
                          notificationCount={searchedRequests.length}
                          color={"collaborators"}
                          title={dictionary["routes"]["manageRequests"]}
                          Icon={NoteAltOutlined}
                        />
                      </Link>
                      <Box sx={{ px: (theme) => theme.spacing(1) }}>
                        <ManageRequestsTables
                          searchedRequests={searchedRequests}
                          mini
                        />
                      </Box>
                    </CollapsableWindow>
                  )}
                  {userHasOneOfRoles([ROLES.JUSTIFICATION_APPROVER], user) && (
                    <CollapsableWindow collapsedSize={50}>
                      <HeaderCollapse
                        notificationCount={plannedAbsences.length}
                        color={"collaborators"}
                        title={dictionary["routes"]["plannedabsences"]}
                        Icon={NoteAltOutlined}
                      />
                      <Box sx={{ px: (theme) => theme.spacing(1) }}>
                        <PlannedAbsences
                          plannedRequests={plannedAbsences}
                          mini
                        />
                      </Box>
                    </CollapsableWindow>
                  )}
                </React.Fragment>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
