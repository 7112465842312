import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Card, Typography, Button, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/it";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useNavigate } from "react-router-dom";
import { useDictionary } from "../hooks";

dayjs.extend(customParseFormat);
dayjs.locale("it");

const daysOfWeek = ["Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"];

const CalendarActivities = ({ searchedDailyAct, datefrom, manage = false }) => {
  const [rowHeights, setRowHeights] = useState([0, 0]); // Store height for each row
  const weekRefs = [useRef([]), useRef([])]; // Refs for each card in each row

  const MIN_CARD_HEIGHT = 350; // Altezza minima
  const MAX_CARD_HEIGHT = 500; // Altezza massima
  const HEADER_HEIGHT = 50;
  // Initialize refs for each week
  weekRefs[0].current = Array(7)
    .fill()
    .map((_, i) => weekRefs[0].current[i] || React.createRef());
  weekRefs[1].current = Array(7)
    .fill()
    .map((_, i) => weekRefs[1].current[i] || React.createRef());

  const start = dayjs(datefrom, "YYYY/MM/DD");

  // 1️⃣ Troviamo l'indice del giorno di partenza (0 = Lun, 1 = Mar, ..., 6 = Dom)
  const startDayIndex = (start.day() + 6) % 7; // Trasforma il formato (Dom=0) -> (Lun=0)

  // 2️⃣ Creiamo la PRIMA settimana partendo dal giorno selezionato
  const firstWeekDays = Array.from({ length: 7 }, (_, i) =>
    start.add(i, "day")
  );
  // 3️⃣ Creiamo la SECONDA settimana in continuità con la prima
  const secondWeekDays = Array.from({ length: 7 }, (_, i) =>
    start.add(i + 7, "day")
  );

  // 4️⃣ Struttura dati modificata per contenere anche notbeforedate e performwithindate
  const weekActivities = [
    Array.from({ length: 7 }, () => ({
      planned: [],
      notbefore: [],
      performwithin: [],
    })),
    Array.from({ length: 7 }, () => ({
      planned: [],
      notbefore: [],
      performwithin: [],
    })),
  ];

  // 5️⃣ Distribuiamo le attività nei giorni giusti
  searchedDailyAct.forEach((activity) => {
    // Gestione plannedstartactivitydate
    if (activity.plannedstartactivitydate) {
      const activityDate = dayjs(
        activity.plannedstartactivitydate,
        "YYYY-MM-DD"
      );
      const diffDays = activityDate.diff(start, "day");

      if (diffDays >= 0 && diffDays < 7) {
        weekActivities[0][diffDays].planned.push(activity);
      } else if (diffDays >= 7 && diffDays < 14) {
        weekActivities[1][diffDays - 7].planned.push(activity);
      }
    }

    // Gestione notbeforedate (giallo)
    if (
      activity.notbeforedate &&
      (!activity.plannedstartactivitydate ||
        activity.notbeforedate !== activity.plannedstartactivitydate)
    ) {
      const notBeforeDate = dayjs(activity.notbeforedate, "YYYY-MM-DD");
      const diffDays = notBeforeDate.diff(start, "day");

      if (diffDays >= 0 && diffDays < 7) {
        weekActivities[0][diffDays].notbefore.push(activity);
      } else if (diffDays >= 7 && diffDays < 14) {
        weekActivities[1][diffDays - 7].notbefore.push(activity);
      }
    }

    // Gestione performwithindate (rosso)
    if (
      activity.performwithindate &&
      (!activity.plannedstartactivitydate ||
        activity.performwithindate !== activity.plannedstartactivitydate)
    ) {
      const performWithinDate = dayjs(activity.performwithindate, "YYYY-MM-DD");
      const diffDays = performWithinDate.diff(start, "day");

      if (diffDays >= 0 && diffDays < 7) {
        weekActivities[0][diffDays].performwithin.push(activity);
      } else if (diffDays >= 7 && diffDays < 14) {
        weekActivities[1][diffDays - 7].performwithin.push(activity);
      }
    }
  });

  // Funzione di ordinamento per attività per orario pianificato
  const sortActivities = (activities, type) => {
    return [...activities].sort((a, b) => {
      let timeA, timeB;

      if (type === "planned") {
        timeA = a.plannedstartactivitytime || "";
        timeB = b.plannedstartactivitytime || "";
      } else if (type === "notbefore") {
        timeA = a.notbeforehour || a.plannedstartactivitytime || "";
        timeB = b.notbeforehour || b.plannedstartactivitytime || "";
      } else if (type === "performwithin") {
        timeA = a.performwithinhour || a.plannedstartactivitytime || "";
        timeB = b.performwithinhour || b.plannedstartactivitytime || "";
      }

      return timeA.localeCompare(timeB);
    });
  };

  // Ordina tutte le attività in tutti i giorni
  for (let w = 0; w < 2; w++) {
    for (let d = 0; d < 7; d++) {
      weekActivities[w][d].planned = sortActivities(
        weekActivities[w][d].planned,
        "planned"
      );
      weekActivities[w][d].notbefore = sortActivities(
        weekActivities[w][d].notbefore,
        "notbefore"
      );
      weekActivities[w][d].performwithin = sortActivities(
        weekActivities[w][d].performwithin,
        "performwithin"
      );
    }
  }

  const calculateRowHeights = () => {
    // Resettiamo prima le altezze per permettere ai contenitori di tornare alle dimensioni naturali
    setRowHeights([0, 0]);

    // Piccola attesa per permettere al DOM di aggiornarsi
    setTimeout(() => {
      // Calcola l'altezza naturale delle card (senza limiti)
      const getCardHeights = (weekIndex) => {
        return weekRefs[weekIndex].current.map((ref) => {
          // Calcola l'altezza di ogni card (considerando anche i bottoni all'interno)
          let cardHeight = 0;
          const cardElement = ref.current;

          if (cardElement) {
            // Ottieni l'altezza naturale sommando le altezze di tutti gli elementi all'interno
            const headerHeight = cardElement.querySelector("h6").offsetHeight;
            const buttonsContainer = cardElement.querySelector("div > div"); // Il container dei bottoni
            const buttonsHeight = buttonsContainer
              ? buttonsContainer.scrollHeight
              : 0; // scrollHeight prende l'altezza totale anche se c'è overflow

            cardHeight = headerHeight + buttonsHeight + 30; // 30px per padding e margini
          }

          return Math.max(cardHeight, MIN_CARD_HEIGHT);
        });
      };

      // Ottieni le altezze naturali delle card
      const week1Heights = getCardHeights(0);
      const week2Heights = getCardHeights(1);

      // Limita le altezze al massimo consentito
      const maxHeight1 = Math.min(Math.max(...week1Heights), MAX_CARD_HEIGHT);
      const maxHeight2 = Math.min(Math.max(...week2Heights), MAX_CARD_HEIGHT);

      setRowHeights([maxHeight1, maxHeight2]);
    }, 50);
  };

  // Calcola l'altezza delle righe quando il componente viene montato o cambiano i dati
  useEffect(() => {
    calculateRowHeights();

    // Aggiungi listener per il ridimensionamento della finestra
    window.addEventListener("resize", calculateRowHeights);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", calculateRowHeights);
    };
  }, [searchedDailyAct, datefrom]);

  // Componente Bottone di attività riutilizzabile
  const ActivityButton = ({ activity, type }) => {
    const dictionary = useDictionary();
    const buttonText = activity.costcenter;
    const buttonNameuser = `(${activity.user})`;
    const activityId = activity.id;
    const navigate = useNavigate();

    // Determina l'orario corretto in base al tipo di attività
    let activityTime = "";
    
    if (type === "planned") {
      if (activity.plannedstartactivitytime) {
        activityTime = activity.plannedstartactivitytime.slice(0, 5);
      } else {
        activityTime = dictionary["dailyactivities"]["allDay"];
      }
    } else if (type === "notbefore") {
      if (activity.notbeforehour) {
        activityTime = `${dictionary["dailyactivities"]["beforeHour"]} ${activity.notbeforehour.slice(0, 5)}`;
      } else {
        activityTime = dictionary["dailyactivities"]["beforeDay"];
      }
    } else if (type === "performwithin") {
      if (activity.performwithinhour) {
        activityTime = `${dictionary["dailyactivities"]["performwithinHour"]} ${activity.performwithinhour.slice(0, 5)}`;
      } else {
        activityTime = dictionary["dailyactivities"]["performwithinDay"];
      }
    }

    const colorMap = {
      planned: "#d1ede3",    // verde acqua ancora più tenue
      notbefore: "#e5dbed",  // lavanda ancora più chiaro
      performwithin: "#f8dfd3", // pesca ancora più chiaro
    };
    
    const hoverMap = {
      planned: "#b8e0d2",    // verde acqua tenue per hover (come il colore base precedente)
      notbefore: "#d6c6e1",  // lavanda chiaro per hover (come il colore base precedente)
      performwithin: "#f2c6b4", // pesca chiaro per hover (come il colore base precedente)
    };

    const borderMap = {
      planned: "#3da47e",     // verde più deciso per il bordo
      notbefore: "#9a6cb8",   // viola più deciso per il bordo
      performwithin: "#d47e56", // arancione-pesca più deciso per il bordo
    };

    // Tooltip aggiuntivo per spiegare il tipo di attività
    const tooltipTexts = {
      planned: dictionary["dailyactivities"]["plannedActivity"],
      notbefore: dictionary["dailyactivities"]["notbeforeActivity"],
      performwithin: dictionary["dailyactivities"]["performwithinActivity"],
    };

    const tooltipText = `${tooltipTexts[type]}: ${buttonText} - ${buttonNameuser}`;
    const buttonColor = colorMap[type];
    const hoverColor = hoverMap[type];

    return (
      <Tooltip title={tooltipText} arrow>
       <Button
        variant="contained"
        size="small"
        fullWidth
        sx={{
          color: "black",
          bgcolor: buttonColor,
          borderLeft: `6px solid ${borderMap[type]}`, // Bordo solo a sinistra e molto più largo
          borderRadius: "2px", // Ridotto per un aspetto più squadrato come un'etichetta
          "&:hover": { 
            bgcolor: hoverColor,
            borderLeft: `6px solid ${borderMap[type]}` // Manteniamo lo stesso bordo durante l'hover
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "auto",
          whiteSpace: "normal",
          padding: "2px 2px",
          paddingLeft: "1px", // Aggiunto per dare un po' più di spazio dopo la barra colorata
          minWidth: "unset",
          mb: -0.2,
          textAlign: "left", // Opzionale: allinea il testo a sinistra per migliorare l'effetto etichetta
        }}
          onClick={() => {
            if (manage){
              navigate(`/daily-activities/${activityId}?mode=edit&role=manage`);
            } else {
              navigate(
                `/daily-activities/${activityId}?mode=view&role=user`
              );
            }
          }}
        >
          {activityTime && (
            <Typography
              variant="caption"
              sx={{
                textAlign: "center",
                fontSize: "0.9rem",
                width: "100%",
                fontWeight: "bold",
                lineHeight: '1.5',
                fontWeight: 'bold',
                textDecoration: 'underline'
              }}
            >
              {activityTime}
            </Typography>
          )}
          <Typography
            variant="button"
            sx={{
              textAlign: "center",
              fontSize: "0.75rem",
              width: "100%",
              wordBreak: "break-word",
              lineHeight: '1.5',
            }}
          >
            {buttonText}
          </Typography>
          <Typography
            variant="button"
            sx={{
              textAlign: "center",
              fontSize: "0.75rem",
              width: "100%",
              wordBreak: "break-word",
              lineHeight: '1.5',
            }}
          >
            {buttonNameuser}
          </Typography>
        </Button>
      </Tooltip>
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      {[firstWeekDays, secondWeekDays].map((weekDays, weekIndex) => (
        <Grid container spacing={0.8} key={weekIndex} sx={{ mb: 2 }}>
          {weekDays.map((day, index) => (
            <Grid key={index} item xs={12} sm={3} md={1.714}>
              <Card
                ref={weekRefs[weekIndex].current[index]}
                sx={{
                  padding: 0.8,
                  textAlign: "center",
                  height: rowHeights[weekIndex]
                    ? `${rowHeights[weekIndex]}px`
                    : "350px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h6" fontWeight="bold">
                  {daysOfWeek[(startDayIndex + index) % 7]}{" "}
                  {day.format("DD/MM")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    mt: 1,
                    flexGrow: 1,
                    overflow: "auto",
                    maxHeight: rowHeights[weekIndex]
                      ? `${rowHeights[weekIndex] - 50}px`
                      : "300px",
                  }}
                >
                  {/* Attività pianificate (blu) */}
                  {weekActivities[weekIndex][index].planned.map(
                    (activity, i) => (
                      <ActivityButton
                        key={`planned-${i}`}
                        activity={activity}
                        type="planned"
                      />
                    )
                  )}

                  {/* Attività notbefore (gialle) */}
                  {weekActivities[weekIndex][index].notbefore.map(
                    (activity, i) => (
                      <ActivityButton
                        key={`notbefore-${i}`}
                        activity={activity}
                        type="notbefore"
                      />
                    )
                  )}

                  {/* Attività performwithin (rosse) */}
                  {weekActivities[weekIndex][index].performwithin.map(
                    (activity, i) => (
                      <ActivityButton
                        key={`performwithin-${i}`}
                        activity={activity}
                        type="performwithin"
                      />
                    )
                  )}
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      ))}
    </Box>
  );
};

export default CalendarActivities;